import request from '@/utils/request'

// timestampStart=1729910773000&timestampEnd=1730083573000

const apiPath = '/mdm/portal/v2401/statistic'
// 获取中兴通知统计（J01定制，注意接口地址前缀）
export function getZteNoticeData(params) {
  return request({
    url: '/mdm/portal/v2401/j01/statistic/zte-notice',
    method: 'get',
    params
  })
}

// ?timestampStart=1728614773000&timestampEnd=1730085854000&appKey=
// 获取通话记录统计
export function getCallRecordData(params) {
  return request({
    url: apiPath + '/call-record',
    method: 'get',
    params
  })
}

// ?timestampStart=1729824373000&timestampEnd=1730085854000&appKey=
// 视频通话短信下发统计
export function getVideoCallData(params) {
  return request({
    url: apiPath + '/video-call',
    method: 'get',
    params
  })
}

// ?timestampStart=1729824373000&timestampEnd=1730085854000
// 指导视频短信下发统计
export function getGuideVideoData(params) {
  return request({
    url: apiPath + '/guide-video',
    method: 'get',
    params
  })
}
