import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401/config'
// 查询配置信息
export function getConfigData(params) {
  return request({
    url: apiPath + '/list',
    method: 'get',
    params
  })
}
// 新增常量配置
export function cerateConstantConfig(data) {
  return request({
    url: apiPath,
    method: 'post',
    data
  })
}
// 更新常量配置
export function updateConstantConfig(data) {
  return request({
    url: apiPath,
    method: 'put',
    data
  })
}
// 删除常量配置
export function deleteConstantConfig(key) {
  return request({
    url: apiPath + `?key=${key}`,
    method: 'delete'
  })
}
