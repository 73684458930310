import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401/video'
// 查询（分页）视频信息
export function getVideoList(params) {
  return request({
    url: apiPath + '/list',
    method: 'get',
    params
  })
}
// 删除版本
export function deleteVideo(id) {
  return request({
    url: apiPath + `?id=${id}`,
    method: 'delete'
  })
}
// 获取视频链接
export function getDotVideoUrl(id) {
  return request({
    url: apiPath + `?id=${id}`,
    method: 'get'
  })
}
// 获取视频打点
export function getVideoTrick(id) {
  return request({
    url: apiPath + `/key-frame/list?id=${id}`,
    method: 'get'
  })
}
// 新增视频打点
export function createVideoTrick(id, data) {
  return request({
    url: apiPath + `/key-frame/create?id=${id}`,
    method: 'post',
    data
  })
}
// 编辑视频打点
export function updateVideoTrick(params) {
  return request({
    url: apiPath + `/key-frame/update`,
    method: 'put',
    params
  })
}
// 删除视频打点
export function deleteVideoTrick(id) {
  return request({
    url: apiPath + `/key-frame/delete?id=${id}`,
    method: 'delete'
  })
}

// 上传视频
export function uploadVideoFile(desc, file) {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    url: apiPath + `?description=${desc}`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data' // 设置Content-Type为application/json
    },
    data: formData
  })
}
// 更新视频
export function updateVideoFile(params, file) {
  let formData = new FormData()
  if (file) {
    formData.append('file', file)
  } else {
    formData = {}
  }
  return request({
    url: apiPath,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data' // 设置Content-Type为application/json
    },
    params,
    data: formData
  })
}
