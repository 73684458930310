import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
// import { Message } from 'element-ui'
import router from './router'
import store from './store'
import '@/styles/index.scss' // global css
// 全局注册elementui
import 'element-ui/lib/theme-chalk/index.css'

import '@/icons'
import '@/utils/directive'

import * as api_author from '@/api/auth'
import * as api_device from '@/api/device'
import * as api_version from '@/api/version'
import * as api_call from '@/api/call'
import * as api_event from '@/api/event'
import * as api_video from '@/api/video'
import * as api_config from '@/api/config'
import * as api_monitor from '@/api/monitor'

import * as util_format from '@/utils/format'

Vue.prototype.$api = {
  ...api_author,
  ...api_device,
  ...api_version,
  ...api_call,
  ...api_event,
  ...api_video,
  ...api_config,
  ...api_monitor
}
Vue.prototype.$tools = {
  ...util_format
}
// Vue.prototype.$message = Message

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount('#app')
