import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401/version'
// 查询（分页）设备信息
export function getVersionList(params) {
  return request({
    url: apiPath + '/list',
    method: 'get',
    params
  })
}
// 创建版本
export function createVersion(data) {
  return request({
    url: apiPath,
    method: 'post',
    data
  })
}
// 更新版本内容
export function updateVersion(data) {
  return request({
    url: apiPath,
    method: 'put',
    data
  })
}
// 删除版本
export function deleteVersion(id) {
  return request({
    url: apiPath + `?id=${id}`,
    method: 'delete'
  })
}
// 下载版本包
export function downloadPackage(id) {
  return request({
    url: apiPath + `/package/download?id=${id}`,
    method: 'get'
  })
}
// 下载开发文档
export function downloadDocument(id) {
  return request({
    url: apiPath + `/document/download?id=${id}`,
    method: 'get'
  })
}
