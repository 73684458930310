import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401/event'
// 获取事件类型
export function getEventType() {
  return request({
    url: apiPath + '/type',
    method: 'get'
  })
}
// 分页查询事件列表
export function getAllEventList(params, data) {
  return request({
    url: apiPath + '/list',
    method: 'post',
    params,
    data
  })
}
