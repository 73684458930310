import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
export const baseURL = ''
// create an axios instance
const service = axios.create({
  baseURL
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    return config
  },
  (err) => Promise.reject(err)
)

// response interceptor
service.interceptors.response.use(
  (res) => {
    // TODO 4. 摘取核心响应数据
    if (res.data.ret === 0 || res.data.ret === undefined) {
      return res
    }
    // TODO 3. 处理业务失败
    // 处理业务失败，给出错误提示，抛出错误
    if (res.data.detail === '未登录或登录失效') {
      Message({
        message: res.data.msg + '跳转登录页...',
        type: 'error',
        duration: 2000
      })
      setTimeout(() => {
        router.push('/login')
      }, 1000)
    } else {
      Message({
        message: res.data.msg || '服务异常！',
        type: 'error',
        duration: 2000
      })
    }

    return Promise.reject(res.data)
  },
  (err) => {
    console.error('err:', err)
    // 错误的默认情况，只给提示
    Message({
      message: err.message || '服务异常！',
      type: 'error',
      duration: 2000
    })
    return Promise.reject(err)
  }
)

export default service
