import Vue from 'vue'
Vue.directive('clickoutside', {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数
        binding.value(e)
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
})

Vue.directive('tooltips', {
  bind(el, binding, vnode, oldVnode) {
    // 鼠标移入时，将浮沉元素插入到body中
    let timer = null
    el.onmouseenter = function(e) {
      // 创建浮层元素并设置样式
      timer = setTimeout(() => {
        const vcTooltipDom = document.createElement('div')
        vcTooltipDom.style.cssText = `
                overflow: auto;
                position:fixed;
                background: '#fff';
                color: '#000';
                box-shadow: rgba(0, 0, 0, 0.295) 1px 2px 10px;
                border-radius: 5px;
                padding: 5px 10px;
                display: inline-block;
                font-size: 14px;
                z-index: 2;
                top: ${e.clientY - 40}px;
                left: ${e.clientX - 40}px;
              `
        // 设置id方便寻找
        vcTooltipDom.setAttribute('id', 'vc-tooltip')
        // 将浮层插入到body中
        document.body.appendChild(vcTooltipDom)
        // 浮层中的文字 通过属性值传递动态的显示文案
        document.getElementById('vc-tooltip').innerHTML = el.getAttribute('tip')
      }, 1000)
    }
    // 鼠标移动时，动态修改浮沉的位置属性
    // el.onmousemove = function(e) {
    //   const vcTooltipDom = document.getElementById('vc-tooltip');
    //   vcTooltipDom.style.top = e.clientY + 15 + 'px';
    //   vcTooltipDom.style.left = e.clientX + 10 + 'px';
    // };
    // 鼠标移出时将浮层元素销毁
    el.onmouseleave = function() {
      // 找到浮层元素并移出
      const vcTooltipDom = document.getElementById('vc-tooltip')
      vcTooltipDom && document.body.removeChild(vcTooltipDom)
      clearTimeout(timer)
      timer = null
    }
  }
})
