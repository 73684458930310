import Vue from 'vue'
import Router from 'vue-router'
import getPageTitle from '@/utils/getPageTitle'
import store from '@/store'

Vue.use(Router)
import Layout from '@/views/layout/LayoutContainer.vue'
const constantRoutes = [{
  path: '/login',
  component: () => import('@/views/login/index')
},
{
  path: '/',
  component: Layout,
  redirect: '/monitor',
  children: [{
    path: 'monitor',
    component: () => import('@/views/monitor/index'),
    meta: {
      title: '首页',
      requireAuth: true
    }
  }]
},
{
  path: '/system',
  component: Layout,
  meta: {
    title: '系统管理',
    requireAuth: true
  },
  children: [
    {
      path: 'version',
      component: () => import('@/views/system/SystemVersion.vue'),
      meta: {
        title: '版本管理',
        requireAuth: true
      }
    },
    {
      path: 'device',
      component: () => import('@/views/appmanage/AppmanageDevice.vue'),
      meta: {
        title: '设备管理',
        requireAuth: true
      }
    },
    {
      path: 'video',
      component: () => import('@/views/video/VideoResources.vue'),
      meta: {
        title: '视频资源管理',
        requireAuth: true
      }
    },
    {
      path: 'config',
      component: () => import('@/views/config/ConstantConfig.vue'),
      meta: {
        title: '常量配置',
        requireAuth: true
      }
    }
    // {
    //   path: 'map',
    //   component: () => import('@/views/appmanage/EquipmentMap.vue'),
    //   meta: {
    //     title: '设备地图',
    //     requireAuth: true
    //   }
    // }
  ]
},
{
  path: '/statistic',
  component: Layout,
  meta: {
    title: '数据中心',
    requireAuth: true
  },
  children: [
    {
      path: 'call',
      name: 'call',
      component: () => import('@/views/statistic/StatisticCall.vue'),
      meta: {
        title: '通话记录',
        requireAuth: true
      },
      children: [
        {
          path: 'detail',
          name: 'detail',
          component: () => import('@/views/statistic/CallDetail.vue'),
          meta: {
            title: '通话详情',
            requireAuth: true
          }
        }
      ]
    },
    {
      path: 'event',
      component: () => import('@/views/statistic/EventSelect.vue'),
      meta: {
        title: '事件查询',
        requireAuth: true
      }
    }
  ]
},
{
  path: '/callout',
  component: () => import('@/views/callout/CalloutAction.vue'),
  meta: {
    title: '发起呼叫',
    requireAuth: false
  }
},
{
  path: '*',
  name: 'NotFound',
  component: () => import('@/views/NotFound'),
  meta: {
    title: '404'
  }
}

]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  })

const router = createRouter()
router.beforeEach(async(to, from, next) => {
  document.title = getPageTitle(to.meta.title) // 显示标题
  const hasConfig = store.getters.config
  if (!hasConfig['device.mode'] && to.path !== '/login') { // 先写死，后续优化
    await store.dispatch('user/setConfig')
  }
  // next()
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (sessionStorage.getItem('isLogin') === 'true') {
      next()
    } else {
      // 未登录，跳转到登录页面
      next('/login')
    }
  } else {
    next()
  }
})
export default router
