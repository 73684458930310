import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401/call-record'
// 查询（分页）设备信息
export function getCallData(params) {
  return request({
    url: apiPath + '/list',
    method: 'get',
    params
  })
}
// 获取录制文件信息
export function getMediaFiles(callId) {
  return request({
    url: apiPath + `/file?callId=${callId}`,
    method: 'get'
  })
}
// 获取视频下载地址
export function getCallVideoUrl(params) {
  return request({
    url: apiPath + '/file/download',
    method: 'get',
    params
  })
}
// 获取历史位置信息
export function getHistoryLocation(id, time) {
  return request({
    url: apiPath + `/location?callId=${id}&callBeginTimestamp=${time}`,
    method: 'get'
  })
}
// 获取短信统计和事件详情列表
export function getEventList(query, data) {
  return request({
    url:
      apiPath +
      `/sms/statistic?callId=${query.callId}&callBeginTimestamp=${query.callBeginTimestamp}&callDuration=${query.callDuration}`,
    method: 'post',
    data
  })
}
// 获取定位信息事件详情列表
export function getLocationList(params, data) {
  return request({
    url: apiPath + '/location/event/list',
    method: 'post',
    params,
    data
  })
}
// 导出通话数据excel
export function exportCallData(params) {
  return request({
    url: apiPath + '/list/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
