const state = {
  // 通知
  notify: {
    total: 0,
    list: []
  }
}

const mutations = {
  setNotify: (state, notify) => {
    state.notify = notify
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
