import { getConfigData } from '@/api/config.js'
import { login } from '@/api/auth.js'

const state = {
  username: '',
  //   token: "",
  config: {}
}

const mutations = {
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  //   SET_TOKEN: (state, token) => {
  //     state.token = token;
  //   },
  SET_CONFIG: (state, config) => {
    state.config = config
  }
}

const actions = {
  setConfig({ commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async(resolve) => {
      const config = {}
      const { data: res } = await getConfigData()
      const newData = res.data.preset.concat(res.data.customizable)
      for (const item of newData) {
        config[item.key] = item.value
      }
      commit('SET_CONFIG', config)
      resolve()
    })
  },
  // user login
  login({ commit }, userInfo) {
    const { loginParams, loginData } = userInfo
    return new Promise((resolve, reject) => {
      login(loginParams, loginData)
        .then((response) => {
          //   commit("SET_TOKEN", data.token);
          commit('SET_USERNAME', loginData.username)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
