import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401/device'
const apij01Path = '/mdm/portal/v2401/j01/device'
// 查询（分页）设备信息
export function getDevices(params) {
  return request({
    url: apij01Path + '/list',
    method: 'get',
    params
  })
}
// 新增设备
export function createDevice(data) {
  return request({
    url: apij01Path,
    method: 'post',
    data
  })
}
// 更新设备
export function updateDevice(data) {
  return request({
    url: apij01Path,
    method: 'put',
    data
  })
}
// 删除设备
export function deleteDevice(id) {
  return request({
    url: apiPath + `?id=${id}`,
    method: 'delete'
  })
}
// 获取设备的openId
export function getDeviceOpenId(id) {
  return request({
    url: apiPath + `/openId?id=${id}`,
    method: 'get'
  })
}
// 更新设备的openId
export function updateDeviceOpenId(id) {
  return request({
    url: apiPath + `/openId?id=${id}`,
    method: 'put'
  })
}
// 批量上传设备信息
export function uploadBatchDevice() {
  return request({
    url: apiPath + '/upload',
    method: 'post'
  })
}
// 查询日志列表
export function getLogList(id) {
  return request({
    url: apiPath + `/log/list?imei=${id}`,
    method: 'get'
  })
}
// 请求终端上传本地日志
export function getDeviceLog(id) {
  return request({
    url: apiPath + `/log/request?id=${id}`,
    method: 'get'
  })
}
// 下载日志
export function downloadLog(key) {
  return request({
    url: apiPath + `/log/download?fileKey=${key}`,
    method: 'get'
  })
}
// 获取请求日志功能是否可用的状态
export function getDeviceLogBtn(id) {
  return request({
    url: apiPath + `/log/availability?imei=${id}`,
    method: 'get'
  })
}
// 获取设备在线状态
export function getDeviceStatus(data) {
  return request({
    url: apiPath + '/onlineStatus',
    method: 'post',
    data
  })
}
// 获取全部启用设备列表
export function searchAllDevice() {
  return request({
    url: apiPath + '/list/all',
    method: 'get'
  })
}
// 获取全部在线设备
export function searchOnlineDevice() {
  return request({
    url: apiPath + '/onlineStatus/all',
    method: 'get'
  })
}
// 批量绑定版本
export function bindDeviceVersion(data) {
  return request({
    url: apiPath + '/version/bind/batch',
    method: 'put',
    data
  })
}
// 获取全部设备列表模式
export function getAllDeviceMode() {
  return request({
    url: apiPath + '/mode/list',
    method: 'get'
  })
}
// 导出数据
export function exportDeviceData(params) {
  return request({
    url: apiPath + '/list/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
// 查看统计信息
export function getOnlineStatistics(imei) {
  return request({
    url: apiPath + `/online/statistic?imei=${imei}`,
    method: 'get'
  })
}
// 分页获取设备上下线明细
export function getDeviceOnline(params) {
  return request({
    url: apiPath + '/online/detail/list',
    method: 'get',
    params
  })
}
