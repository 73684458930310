<template>
  <div class="has-logo">
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        ref="menu"
        :background-color="variables.menuBg"
        :collapse="isCollapse"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
        router
        :default-active="$route.path"
      >
        <el-submenu index="/monitor">
          <template slot="title">
            <i class="el-icon-s-home" />
            <span>首页</span>
          </template>
          <el-menu-item index="/monitor">首页</el-menu-item>
        </el-submenu>
        <el-submenu index="/system">
          <template slot="title">
            <i class="el-icon-s-tools" />
            <span>系统管理</span>
          </template>
          <el-menu-item index="/system/version">版本管理</el-menu-item>
          <el-menu-item index="/system/device">设备管理</el-menu-item>
          <el-menu-item index="/system/video">视频资源管理</el-menu-item>
          <el-menu-item index="/system/config">常量配置</el-menu-item>
          <!-- <el-menu-item index="/system/map">设备地图</el-menu-item> -->
        </el-submenu>
        <el-submenu index="/statistic">
          <template slot="title">
            <i class="el-icon-location" />
            <span>数据中心</span>
          </template>
          <el-menu-item index="/statistic/call">通话记录</el-menu-item>
          <el-menu-item index="/statistic/event">事件查询</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
    <div v-if="!isCollapse" class="version">版本号 {{ versionInfo }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import variables from '@/styles/variables.module.scss'

export default {
  components: { Logo },
  data() {
    return {
      // eslint-disable-next-line no-undef
      versionInfo: VERSION_INFO.version
    }
  },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  watch: {
    $route: {
      handler(newValue) {
        // 监视路由是不是home状态，是home就折叠所有的菜单项
        if (newValue.path === '/home') {
          const menu = this.$refs.menu
          menu.$children.forEach((submenu) => {
            if (submenu.$options.propsData.index !== '/home') {
              menu.close(submenu.$options.propsData.index)
            }
          })
          this.$refs.menu.activeIndex = null
        }
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.version {
  color: #828a9b;
  font-size: 12px;
  text-align: center;
  padding-top: 13px;
}
</style>
