<template>
  <div :class="classObj" class="layout-container">
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <navbar />
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      }
    }
  }
  //   components: {
  //     Navbar,
  //     Sidebar,
  //     AppMain
  //   },
  //   mixins: [ResizeMixin],
  //   computed: {
  //     sidebar() {
  //       return this.$store.state.app.sidebar
  //     },
  //     device() {
  //       return this.$store.state.app.device
  //     },
  //     fixedHeader() {
  //       return this.$store.state.settings.fixedHeader
  //     },
  //     classObj() {
  //       return {
  //         hideSidebar: !this.sidebar.opened,
  //         openSidebar: this.sidebar.opened,
  //         withoutAnimation: this.sidebar.withoutAnimation,
  //         mobile: this.device === 'mobile'
  //       }
  //     }
  //   },
  //   methods: {
  //     handleClickOutside() {
  //       this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
  //     }
  //   }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.module.scss';
.layout-container {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  .el-aside {
    background-color: $menuBg;
    .el-menu {
      border-right: none;
    }
  }
  .el-header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dropdown__box {
      display: flex;
      align-items: center;
      .el-icon {
        color: #999;
        margin-left: 10px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  .el-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #666;
  }
}
</style>
