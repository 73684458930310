/**
 * 日期时间转化方法
 * @param {string} timeValue 传入的时间戳
 * @param {string} call 是否展示时分秒
 * @returns
 */
export function reverseParse(timeValue, call) {
  if (!timeValue) return '无'
  var date = new Date() // 创建当前时间的Date对象
  date.setTime(timeValue) // 设置时间值
  var outTime
  var year = date.getFullYear() // 获取年份
  var month = (date.getMonth() + 1).toString().padStart(2, '0') // 获取月份（注意需要加上1）
  var day = date.getDate().toString().padStart(2, '0') // 获取日期
  var hour = date.getHours().toString().padStart(2, '0')
  var minutes = date.getMinutes().toString().padStart(2, '0')
  var seconds = date.getSeconds().toString().padStart(2, '0')
  outTime = call
    ? `${year}.${month}.${day} ${hour}:${minutes}:${seconds}`
    : `${year}年${month}月${day}日`
  return outTime // 返回格式化后的日期字符串
}

/**
 * 时间转化方法
 * @param {string} value 传入的毫秒值
 */
export function transTimestampToTime(value, sign) {
  if ((value === 0 || value < 1000) && sign) {
    return '00:00:00'
  } else if (value === 0 || value < 1000) {
    return '0秒'
  }
  var timestamp = parseInt(value) / 1000 // 毫秒转秒
  // 小时取余数
  const remainder = timestamp % 3600
  // 时、分、秒
  let hour, minute, second
  if (remainder === 0) {
    // 整除 小时
    hour = parseInt(timestamp / 3600)
      .toString()
      .padStart(2, '0')
  } else {
    hour = parseInt(timestamp / 3600)
      .toString()
      .padStart(2, '0')
    const remainderMinute = remainder % 60
    if (remainderMinute === 0) {
      // 整除 分钟
      minute = parseInt(remainder / 60)
        .toString()
        .padStart(2, '0')
      second = '00'
    } else {
      minute = parseInt(remainder / 60)
        .toString()
        .padStart(2, '0')
      second = parseInt(remainderMinute).toString().padStart(2, '0')
    }
  }
  let text = ''
  if (sign) {
    text = hour + sign + minute + sign + second
  } else {
    if (hour > 0) {
      text += hour + '时'
    }
    if (minute > 0) {
      text += minute + '分'
    }
    if (second > 0) {
      text += second + '秒'
    }
  }

  return text
}
/**
 *
 * @param {string} timeString 时间格式例 HH:mm:ss
 * @returns 毫秒值
 */
export function timeStringToMilliseconds(timeString) {
  // 分离小时、分钟和秒
  const [hours, minutes, seconds] = timeString.split(':').map(Number)
  // 计算总秒数
  const totalSeconds = hours * 3600 + minutes * 60 + seconds
  // 将总秒数转换为毫秒
  return totalSeconds * 1000
}
