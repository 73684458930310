import request from '@/utils/request'

const apiPath = '/mdm/portal/v2401'

export function login(params, data) {
  return request({
    url: apiPath + '/user/login',
    method: 'post',
    params,
    data
  })
}
// 获取appName筛选内容
export function getAppList() {
  return request({
    url: apiPath + '/app/list',
    method: 'get'
  })
}
