/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

export function validAge(rule, value, callback) {
  if (value) {
    if (!isNaN(value)) {
      if (value < 0 || value > 200) {
        callback(new Error('请正确输入年龄'))
      } else {
        callback()
      }
    } else {
      callback(new Error('年龄需为数字'))
    }
  } else {
    callback(new Error('请输入年龄'))
  }
}

export function validName(rule, value, callback) {
  var pattern = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
  )
  if (value) {
    if (pattern.test(value)) {
      callback(new Error('姓名不应包含特殊符号'))
    } else if (value.length > 20) {
      callback(new Error('姓名不得超过20个字符'))
    } else {
      callback()
    }
  } else {
    callback(new Error('请输入姓名'))
  }
}

/** 身份证 */
export function validCardId(rule, value, callback) {
  const sfzReg =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  if (value) {
    if (!sfzReg.test(value)) {
      callback(new Error('证件号不合法！'))
    } else {
      callback()
    }
  } else {
    callback(new Error('请输入证件号'))
  }
}

/** 质检规则地区 */
// export function validQualityRuleOrg(rule, value, callback) {
//   if (value == null || value.length == 0) {
//     callback(new Error("请选择地区"));
//   } else {
//     callback();
//   }
// }

export function validPayPeriod(rule, value, callback) {
  if (value == null || value.length <= 0) {
    callback(new Error('请输入有效的期数'))
  } else {
    callback()
  }
}
