<template>
  <div id="app">
    <router-view />
    <TimeoutLogin />
  </div>
</template>

<script>
import TimeoutLogin from '@/components/TimeoutLogin'
export default {
  name: 'App',
  components: {
    TimeoutLogin
  }
}
</script>
<style scoped>
.v-modal {
  opacity: 0.5 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}
</style>
