<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="includeName">
        <router-view :key="key" />
      </keep-alive>
    </transition>
    <!-- <transition name="fade-transform" mode="out-in">
      <router-view v-if="!$route.meta.keepAlive" :key="key" />
    </transition> -->
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {
      includeName: []
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  watch: {
    $route(to, from) {
      console.log(to, from)
      if (to.name === 'detail' || from.name === 'detail' || to.name === 'call') {
        this.includeName = ['StatisticCall']
      } else {
        this.includeName = []
      }
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
